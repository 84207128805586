import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      © 2023. CHOIYUNSUK ALL RIGHTS RESERVED.
    </div>
  );
};

export default Footer;
